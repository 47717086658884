<template>
  <span class="mb-0 ms-2" :class="{ h6: short, 'navbar-brand': !short }">
    <b
      v-if="status == 'New'"
      class="bi bi-file-earmark-text text-warning"
      title="Novi dokument"
      alt="Novi dokument"
    >
      {{ short == true ? "NR" : "Novi dokument" }}
    </b>
    <!-- <button
      v-if="status == 'New' && actions == true"
      class="btn btn-success bi bi-check-lg ms-2"
      type="button"
      @click="$emit('saveAndLock')"
    ></button> -->
    <b
      v-if="status == 'Done'"
      class="bi bi-file-earmark-check text-success"
      title="Sacuvan dokument"
      alt="Sacuvan dokument"
    >
      {{ short == true ? "ZR" : "Zaključan dokument" }}</b
    >
    <b
      v-if="status == 'Deleted'"
      class="bi bi-file-earmark-x text-danger"
      title="Obrisan dokument"
      alt="Obrisan dokument"
    >
      {{ short == true ? "SR" : "Storniran dokument" }}</b
    >
  </span>
</template>

<script>
export default {
  name: "invoice-status",
  props: {
    status: {
      type: String,
      required: true,
      default: "New",
    },
    actions: {
      type: Boolean,
      default: false,
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style scoped>
</style>
