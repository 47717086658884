<template>
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex">
      <button v-if="showBackButton()"
          class="btn btn-light bi bi-chevron-left btn-sm me-3"
          type="button"
          @click="$router.go(-1)"
        ></button>
      <span class="navbar-brand mb-0 h1"
        >Dokument: {{ getType() }}
        <invoice-status :status="status" />
      </span>
      </div>

      <form class="d-flex">
        <button
          v-if="status == 'New'"
          class="btn btn-success bi bi-check-lg me-2 btn-sm"
          type="button"
          @click="$emit('save')"
        >
          Sačuvaj
        </button>
        <button
          v-if="status == 'New' && $route.params.type == 'Proform'"
          class="btn btn-success bi bi-check-lg me-2 btn-sm"
          type="button"
          @click="$emit('saveAndLock')"
        >
          Sačuvaj i zaključaj
        </button>
        <!-- <button
          v-if="status == 'Done' && $route.params.type == 'Proform' && !(hidden.includes('advance'))"
          class="btn btn-success bi bi-journal-plus me-2 btn-sm"
          type="button"
          @click="$emit('newAdvance')"
        >
          Kreiraj Avans
        </button> -->
        <button
          v-if="status == 'Done' && $route.params.type != 'Normal'"
          class="btn btn-success bi bi-journal-plus me-2 btn-sm"
          type="button"
          @click="$emit('newNormal')"
        >
          Kreiraj Račun
        </button>
        <button
          v-if="$route.params.type != 'Proform' && !(hidden.includes('eInvoice'))"
          class="btn btn-primary bi bi-printer btn-sm me-2"
          type="button"
          @click="status == 'Done' ? $emit('eInvoice') : $emit('saveAndLock')"
        >
          @Račun
        </button>
        <!-- @click="$emit('eInvoice')" -->
        <button
          v-if="$route.params.type != 'Proform' && !(hidden.includes('fiscal'))"
          class="btn btn-warning bi bi-printer btn-sm me-2"
          type="button"
          @click="$emit('fiscal')"
        >
          Fiskalizacija
        </button>
        <button
          class="btn btn-danger bi bi-x-lg btn-sm"
          type="button"
          @click="goBack()"
        ></button>
        <button
          class="btn btn-success bi bi-printer ms-2 btn-sm"
          type="button"
          @click="$emit('print')"
        ></button>
        <button
          v-if="status != 'Deleted'"
          class="btn btn-danger bi bi-trash ms-2 btn-sm"
          type="button"
          @click="$emit('delete')"
        ></button>
      </form>
    </div>
  </nav>
</template>

<script>
import InvoiceStatus from "../InvoiceStatus";
import utils from "../../../utils/common";
export default {
  name: "invoice-header",
  components: {
    InvoiceStatus,
  },
  props: {
    status: {
      type: String,
      required: true,
      default: "New",
    },
    hidden: {
      default: [],
    },
  },
  methods: {
    getType() {
      return utils.typeName(this.$route.params.type);
    },
    goBack() {  
      // console.log(window.history.length);
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        // @grant        window.close
        window.close();
      }
    },
    showBackButton() {
      return window.history.length > 1;
    },
  },
  mounted() {
    
  },
};
</script>

<style scoped>
</style>
